import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const Form = () => import('../page/Form')

const routes = [
    { name: 'Form', path: '/', component: Form }
]

const router = new Router({
    mode: 'history',
    routes
})
  
export default router